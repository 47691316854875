var exports = {};
/* Validation functions copied from check-types package - https://www.npmjs.com/package/check-types */
function isFunction(data) {
  return typeof data === "function";
}
function isNonEmptyString(data) {
  return isString(data) && data !== "";
}
function isDate(data) {
  return isInstanceStrict(data, Date) && isInteger(data.getTime());
}
function isEmptyString(data) {
  return data === "" || data instanceof String && data.toString() === "";
}
function isString(data) {
  return typeof data === "string" || data instanceof String;
}
function isObject(data) {
  return toString.call(data) === "[object Object]";
}
function isInstanceStrict(data, prototype) {
  try {
    return data instanceof prototype;
  } catch (error) {
    return false;
  }
}
function isInteger(data) {
  return typeof data === "number" && data % 1 === 0;
}
/* End validation functions */

function validate(bool, cb, options) {
  if (!isFunction(cb)) {
    options = cb;
    cb = null;
  }
  if (!isObject(options)) options = {
    Error: "Failed Check"
  };
  if (!bool) {
    if (cb) {
      cb(new ParameterError(options));
    } else {
      throw new ParameterError(options);
    }
  }
}
class ParameterError extends Error {
  constructor(...params) {
    super(...params);
  }
}
exports.ParameterError = ParameterError;
exports.isFunction = isFunction;
exports.isNonEmptyString = isNonEmptyString;
exports.isDate = isDate;
exports.isEmptyString = isEmptyString;
exports.isString = isString;
exports.isObject = isObject;
exports.validate = validate;
export default exports;